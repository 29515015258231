<template>
  <div class="h-screen flex items-center justify-center">
    <h1 class="text-white text-3xl">Not Found</h1>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>